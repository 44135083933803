import React, { Component, Children } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import './carousel.css'

class Carousel extends Component {

    state = {
        activePane: 0,
        itemCount: 0
    }

    incrementActivePane = () => {
        if (this.state.activePane === this.state.itemCount - 1) {
            this.setState({
                activePane: 0
            })
        } else {
            this.setState(prevState => ({
                activePane: prevState.activePane + 1
            }))
        }
    }

    clickNext = () =>{
        clearInterval(this.interval);
        this.incrementActivePane();
    }

    decrementActivePane = () => {
        if (this.state.activePane === 0) {
            this.setState(prevState => ({
                activePane: prevState.itemCount - 1
            }))
        }
        else {
            this.setState(prevState => ({
                activePane: prevState.activePane - 1
            }))
        }
    }

    clickPrev = () => {
        clearInterval(this.interval);
        this.decrementActivePane();
    }

    componentDidMount = () => {
        this.setState({ itemCount: this.props.images.length });
        this.interval = setInterval(this.incrementActivePane, 5000);
    }

    render() {

        return (
            <div id="carousel">
                {this.state.itemCount > 1 ?
                    <span className="prev" onClick={this.clickPrev}><FontAwesomeIcon icon={faChevronLeft} size="2x" /></span>
                    : null}
                    <div className="items">
                        {Children.map(this.props.images, (item, index) => {
                            return (
                                <div className={index === this.state.activePane ? "image active" : "image"} style={{ backgroundImage: "url(" + item + ")" }} />
                            )
                        })}
                        <div className="content">
                            <div>
                                {this.props.children.props.children[0]}
                                {this.props.children.props.children[1]}
                                {this.props.children.props.children[2]}
                            </div>
                        </div>
                    </div>
                {this.state.itemCount > 1 ?
                    <span className="next" onClick={this.clickNext}><FontAwesomeIcon icon={faChevronRight} size="2x" /></span>
                    : null}
            </div>
        )
    }
}

export default Carousel



