import React from 'react'
import { navigate } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';
import { Col } from 'reactstrap';

import './productCard.css'

const navigateTo = (props) => {
    if (props.navigateTo) {
        navigate(props.navigateTo);
    }
    else {
        window.open(props.href, '_blank');
    }
}

const ProductCard = (props) => (
    <Col xs="12" sm="6" md="6" lg="4">
        <ScrollAnimation animateIn="fadeInUp" delay={props.delay} animateOnce={true}>
            <div className="products-col" onClick={() => navigateTo(props)}>
                <div className="img">
                    <img src={props.img} alt="" />
                    <div className="icon"><FontAwesomeIcon icon={props.icon} /></div>
                </div>
                <div className="product-info">
                    <h2 className="title">{props.title}</h2>
                    <p>
                        {props.desc}
                    </p>
                </div>
            </div>
        </ScrollAnimation>
    </Col>
)

export default ProductCard;